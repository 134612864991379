<template>
  <div class="protokoll-unterschriften"  v-if="protokollid">

    <!-- Fall 1: Unterschriften existieren bereits -->
    <CRow v-if="unterschriften.length > 0" class="pb-5 preview">
      <CCol sm="5">
        <div class="float-left">
          <ImageSignature :unterschriften="unterschriften" selektor="unterschrift-monteur" title="Unterschrift Monteur"/>
          <hr/>
          <small>Unterschrift des Monteurs</small>
        </div>
      </CCol>
      <CCol sm="6">
        <div class="float-right">
          <ImageSignature :unterschriften="unterschriften" selektor="unterschrift-auftraggeber" title="Unterschrift Auftraggeber"/>
          <hr/>
          <small>Unterschrift des Auftraggebers</small>
        </div>
      </CCol>
    </CRow>

    <!-- Fall 2: Unterschriften existieren noch nicht -->
    <CRow v-if="unterschriften.length == 0" class="preview">
      <CCol sm="12">
        <CRow>
          <CCol class="col-12 col-12-print col-md-6">
            <div class="float-left w-100">
              <FormularSignature v-model="imageUnterschriftMonteur" form-label="Unterschrift des Monteurs"/>
            </div>
          </CCol>
          <CCol class="col-12 col-12-print col-md-6">
            <div class="float-right w-100">
              <FormularSignature v-model="imageUnterschriftAuftraggeber" form-label="Unterschrift Auftraggeber"/>
            </div>
          </CCol>
        </CRow>
      </CCol>
    </CRow>

    <table v-if="unterschriften.length > 0" class="print-only print-header2">
      <tbody>
      <tr>
        <td>
          <div class="float-left">
            <ImageSignature :unterschriften="unterschriften" selektor="unterschrift-monteur" title="Unterschrift Monteur"/>
            <hr/>
            <small>Unterschrift des Monteurs</small>
          </div>
        </td>
        <td>
        <div class="float-right">
          <ImageSignature :unterschriften="unterschriften" selektor="unterschrift-auftraggeber" title="Unterschrift Auftraggeber"/>
          <hr/>
          <small>Unterschrift des Auftraggebers</small>
        </div>
        </td>
      </tr>
      </tbody>
    </table>

    <table v-if="unterschriften.length == 0" class="print-only  print-header2">
      <tbody>
      <tr>
        <td>
          <div class="float-left">
            <FormularSignature v-model="imageUnterschriftMonteur" form-label="Unterschrift des Monteurs"/>
          </div>
        </td>
        <td>
          <div class="float-right">
            <FormularSignature v-model="imageUnterschriftAuftraggeber" form-label="Unterschrift Auftraggeber"/>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <CRow v-if="unterschriften.length == 0" class="preview unterschriften-speichern pb-3">
      <CCol sm="12">
        <CButton
          class="btn-block px-4"
          color="success"
          v-on:click="saveUnterschriften()"
        >
          <CIcon name="cil-library-add"/>
          &nbsp;
          Unterschriften speichern
        </CButton>
      </CCol>
    </CRow>
    <CModal
      title='Protokoll erfolgreich angelegt'
      color="success"
      size="sm"
      :show="successModal"
    >
      Die Unterschriften wurden gespeichert! Wollen sie zurück oder drucken?
      <br/>
      <br/>
      <div class="d-none alert alert-warning">
        Die Protokolle werden automatisch jeden Tag um 23:00 Uhr als PDF gespeichert. <br/>
        Die Protokolle werden erneut gespeichert, wenn diese aktualisiert wurden und z.B die Unterschrift nachgereicht wird.
      </div>
      <template #footer>
        <div style="float: left">
          <CButton  color="default" @click="$router.push('/protokoll')" class="mr-2">zurück zu Protokoll</CButton>
        </div>
        <div>
          <CButton @click="successModal = false" color="info" class="mr-2">Drucken</CButton>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from 'vue'
import FormularSignature from '@/components/FormElements/Signature'
import ImageSignature from '@/views/Protokoll/components/Bilder/Unterschriften'

export default {
  name: 'ProtokollUnterschriften',
  components: {
    FormularSignature,
    ImageSignature
  },
  props: {
    protokollid: [Number, String]
  },
  data () {
    return {
      successModal: false,
      unterschriften: [],
      imageUnterschriftMonteur: null,
      imageUnterschriftAuftraggeber: null
    }
  },
  mounted () {
    this.setDefaults()
    this.loadUnterschriften()
  },
  watch: {
    protokollid: function () {
      this.loadUnterschriften()
    }
  },
  methods: {
    setDefaults () {
      this.unterschriften = []
      this.imageUnterschriftAuftraggeber = null
      this.imageUnterschriftMonteur = null
    },
    loadUnterschriften () {
      // Aktuelle Hausanschluss mit Axios holen
      const self = this
      if (this.protokollid) {
        Vue.axios.get('/protokoll/unterschriften/get/' + this.protokollid)
          .then((response) => {
            self.unterschriften = response.data
          })
          .catch(function () {
            self.unterschriften = []
          })
      }
    },
    saveUnterschriften () {
      this.$eventBus.$emit('emit-signature')
      this.saveImage('unterschrift-monteur', this.imageUnterschriftMonteur)
        .then(() => {
          if (this.imageUnterschriftAuftraggeber) {
            this.saveImage('unterschrift-auftraggeber', this.imageUnterschriftAuftraggeber)
              .then(() => {
                this.loadUnterschriften()
                this.successModal = true
              })
          } else {
            this.loadUnterschriften()
            this.successModal = true
          }
        })
    },
    saveImage (selektor, image) {
      if (image) {
        const formData = new FormData()
        formData.append('selektor', selektor)
        formData.append('protokollid', this.protokollid)
        formData.append('uploadfile', image)

        return Vue.axios.post(
          '/protokoll/unterschriften/upload',
          formData,
          {
            headers: {
              'content-type': `multipart/form-data; boundary=${formData._boundary}`
            }
          }
        )
      }
    }
  }
}
</script>

<style lang="scss">

  table.print-header2{
    table-layout: fixed;
    width: 950px !important;
    border-spacing: 10px !important;
  }

  table.print-header2 td:nth-child(1){
    padding: 50px !important;
    width: 45% !important;
  }

  table.print-header2 td:nth-child(2){
    padding: 50px !important;
    width: 45% !important;
    text-align: right;
  }

  table.print-only{
    display:none;
  }
  @media print {
    .preview{
        display:none;
    }
    table.print-only{
      display:table;
    }
    .col-12-print{
      width: fit-content !important;
    }
    div.unterschriften-speichern{
      display: none !important;
    }
  }

  @media only screen and (min-width: 1248px) {
    div.unterschriften-speichern{
      display: block;
    }
  }

  div.protokoll-unterschriften {
    margin-top: 60px;
    page-break-inside: avoid;
  }
</style>
