<template>
  <div id="print-protokoll" v-if="protokoll">
    <CRow class="preview pt-4 pt-4">
      <CCol class="text-center pr-4">
        <div class="mx-5 alert alert-warning fs-6 text-left" style="font-size: 10px;">
          Die Protokolle werden automatisch jeden Tag um 23:00 Uhr als PDF gespeichert. <br/>
          Die Protokolle werden erneut gespeichert, wenn diese aktualisiert wurden und z.B die Unterschrift nachgereicht wird.
        </div>
        <button class="btn p-4 btn-success" @click="printWindow()"><CIcon name="cil-print" size="sm"/> <strong>Drucken</strong></button>
        <hr/>
      </CCol>
    </CRow>
    <!-- Firmenlogo -->
    <CRow class="logo">
      <CCol size="12" class="text-center">
        <CImg fluid src="/img/printlogo.png"/>
      </CCol>
    </CRow>

    <!-- Adresse Kunde / Hopster -->
    <CRow class="preview mt-4">
      <CCol sm="8" class="kundenadresse" v-if="protokoll.kunde">
        <CRow>
          <CCol>
            <small>Firma/Herr/Frau</small> <br/>
            <strong>{{protokoll.kunde.name1}}</strong> <br/>
            <span v-if="protokoll.kunde.name2 && protokoll.kunde.name2 != 'NULL'" class="d-block">{{protokoll.kunde.name2}}</span>
            <span v-if="protokoll.kunde.zusatz && protokoll.kunde.zusatz != 'NULL'" class="d-block">{{protokoll.kunde.zusatz}}</span>
            {{protokoll.kunde.strasse}} {{protokoll.kunde.hausnr}} <br/>
            {{protokoll.kunde.plz}} {{protokoll.kunde.ort}} <br/>
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="4" class="hopsteradresse">
        <div class="">
          <p class="hopsteradresse">
            Jacksonring 2 · 48429 Rheine <br>
            Telefon (0 59 71) 800 157-0 <br>
            Telefon (0 59 71) 8 54 61 <br>
            Telefax (0 59 71) 8 41 11 <br>
            E-Mail: info@hopster-lambers.de <br>
            Internet: http://www.hopster-lambers.de
          </p>
        </div>
      </CCol>
    </CRow>

    <CRow class="preview">
      <CCol sm="8" class="kundenadresse" v-if="protokoll.kunde">
        <!-- Optionales Projekt -->
        <CRow class="" v-if="protokoll.projekt">
          <CCol>
            <strong>Baustelle:</strong>
            {{protokoll.projekt.name}}
            <span v-if="protokoll.projekt.zusatz">{{protokoll.projekt.zusatz}}</span>
            <span v-if="protokoll.projekt.strasse">, {{protokoll.projekt.strasse}}</span>
            <span v-if="protokoll.projekt.plz">, {{protokoll.projekt.plz}} {{protokoll.projekt.ort}}</span>
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="4" class="hopsteradresse">
        <div class="">
          <p class="leistung" v-if="protokoll.leistung">
            <strong>Erbrachte Leistung:</strong> <br/>
            {{protokoll.leistung.name}}
          </p>
          <p>
            Rheine, den {{protokoll.datum|moment('DD.MM.YYYY')}}
            <br>
            Protokoll {{protokoll.protokollid}}
          </p>
        </div>
      </CCol>
    </CRow>
    <div class="only-print">
      <table class="print-header">
        <tbody>
          <tr>
            <td>
              <div class="kundenadresse">
                <small>Firma/Herr/Frau</small> <br/>
                <strong>{{protokoll.kunde.name1}}</strong> <br/>
                <span v-if="protokoll.kunde.name2 && protokoll.kunde.name2 != 'NULL'" class="d-block">{{protokoll.kunde.name2}}</span>
                <span v-if="protokoll.kunde.zusatz && protokoll.kunde.zusatz != 'NULL'" class="d-block">{{protokoll.kunde.zusatz}}</span>
                {{protokoll.kunde.strasse}} {{protokoll.kunde.hausnr}} <br/>
                {{protokoll.kunde.plz}} {{protokoll.kunde.ort}} <br/>
              </div>
            </td>
            <td>
              <div class="">
                <p class="hopsteradresse">
                Jacksonring 2 · 48429 Rheine <br>
                Telefon (0 59 71) 800 157-0 <br>
                Telefon (0 59 71) 8 54 61 <br>
                Telefax (0 59 71) 8 41 11 <br>
                E-Mail: info@hopster-lambers.de <br>
                Internet: http://www.hopster-lambers.de
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div v-if="protokoll.projekt">
                <strong>Baustelle:</strong>
                {{protokoll.projekt.name}}
                <span v-if="protokoll.projekt.zusatz">{{protokoll.projekt.zusatz}}</span>
                <span v-if="protokoll.projekt.strasse">, {{protokoll.projekt.strasse}}</span>
                <span v-if="protokoll.projekt.plz">, {{protokoll.projekt.plz}} {{protokoll.projekt.ort}}</span>
              </div>
            </td>
            <td>
              <div class="">
                <p class="leistung" v-if="protokoll.leistung">
                  <strong>Erbrachte Leistung:</strong> <br/>
                  {{protokoll.leistung.name}}
                </p>
                <p>
                  Rheine, den {{protokoll.datum|moment('DD.MM.YYYY')}}
                  <br>
                  Protokoll {{protokoll.protokollid}}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <CRow class="mt-4">
      <CCol sm="12">
        <hr/>
      </CCol>
    </CRow>

    <!-- Arbeitsbeschreibung -->
    <CRow class="mt-4" v-if="protokoll.arbeitsbeschreibung">
      <CCol sm="12">
        <h3>Arbeitsbeschreibung:</h3>
        {{protokoll.arbeitsbeschreibung}}
      </CCol>
    </CRow>

    <!-- Material -->
    <CRow class="mt-4" v-if="protokoll.materialien">
      <CCol sm="12">
        <h3>Material</h3>
        <table class="table table-striped table-bordered material">
          <thead>
            <tr>
              <th>Art.-Nr.</th>
              <th>Stck./m</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="protokoll.materialien.length == 0">
            <tr>
              <td colspan="3">-</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(item, index) in protokoll.materialien" v-bind:key="index">
              <td>{{item.artikelnr}}</td>
              <td>{{item.stueck}}</td>
              <td>{{item.material}}</td>
            </tr>
          </tbody>
        </table>

      </CCol>
    </CRow>

    <div v-if="protokoll.materialien.length > 12" class="pagebreak"> </div>
    <!-- Arbeitszeiten -->
    <CRow class="mt-4" v-if="protokoll.arbeitszeiten">
      <CCol sm="12">
        <h3>Arbeitszeiten</h3>
        <table class="table table-striped table-bordered arbeitszeit">
          <thead>
            <tr>
              <th>Datum</th>
              <th>Dauer</th>
              <th>Mitarbeiter</th>
              <th>Tätigkeit</th>
            </tr>
          </thead>
          <tbody v-if="protokoll.arbeitszeiten.length == 0">
          <tr>
            <td colspan="4">-</td>
          </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(item, index) in protokoll.arbeitszeiten" v-bind:key="index">
              <td>{{item.datum|moment('DD.MM.YYYY')}}</td>
              <td nowrap="nowrap">{{item.dauer}} Std.</td>
              <td>
                <span v-if="item.mitarbeiter.titel == '10'">Helfer</span>
                <span v-if="item.mitarbeiter.titel == '20'">Programmierer</span>
                <span v-if="item.mitarbeiter.titel == '30'">Monteur</span>
                <span v-if="item.mitarbeiter.titel == '40'">Obermonteur</span>
                <span v-if="item.mitarbeiter.titel == '50'">Kundendiensttechniker</span>
                <span v-if="item.mitarbeiter.titel == '60'">Meister</span>
                <span v-if="item.mitarbeiter.titel == '70'">Systemtechniker</span>
                {{item.mitarbeiter.name}}
              </td>
              <td>
                {{item.taetigkeit}}
              </td>
            </tr>
          </tbody>
        </table>
      </CCol>
    </CRow>

    <!-- Vollständig ausgeführt? -->
    <CRow class="mt-4">
      <CCol sm="12">
        <strong>Arbeiten vollständig ausgeführt:</strong>
        &nbsp;
        <span v-if="protokoll.abgeschlossen" >Ja</span>
        <span v-else>Nein</span>
        <br/>
        <strong v-if="getFormatedDate(protokoll.datum) >= '10.09.2024'">Leistung abgenommen</strong>
      </CCol>
    </CRow>

    <!-- Unterschriften -->
    <Unterschriften :protokollid="uuid"/>
  </div>
</template>

<script>
import Vue from 'vue'
import Unterschriften from '@/views/Protokoll/components/Unterschriften'
import moment from 'moment'
export default {
  name: 'protokoll-print',
  components: {
    Unterschriften
  },
  layout: null,
  props: {
    uuid: [Number, String]
  },
  data () {
    return {
      protokoll: null
    }
  },
  mounted () {
    if (this.uuid) {
      Vue.axios.get('/protokoll/details/' + this.uuid)
        .then((response) => {
          this.protokoll = response.data
        })
    }
  },
  methods: {
    getFormatedDate (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    printWindow: function () {
      window.print()
    }
  }
}
</script>

<style lang="scss">

/* Set A4 size */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Style content with shaded background */
.content {
  width: 80%;
  /* Adjust the width as needed */
  height: 80%;
  /* Adjust the height as needed*/
  padding: 20px;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  /* Light gray shade */
}

 table.print-header{
   border-spacing: 10px !important;
   table-layout: fixed;
   width: 950px !important;
 }

 table.print-header td:nth-child(1) {
   vertical-align: top;
   width: 45%;
 }

 table.print-header td:nth-child(2){
   div {
     padding-left: 100px !important;
   }
   width: 55%;
   text-align: left;
 }

 .only-print{
   display: none;
 }

  @media print{
    /* Set content to fill the entire A4 page */
    #print-protokoll{
      margin: auto;
      padding: 50px;
    }
    .only-print{
      display: block;
    }
  }

  @media print {
    .pagebreak { page-break-before: always; } /* page-break-after works, as well */
  }

  @media print {
    .preview{
      display: none !important;
    }
     .header {
       display: none !important;
     }
  }

  @media only screen and (min-width: 1248px) {
    #print-protokoll{
      width: 50%;
      margin: auto;
      padding: 5px;
    }
  }

  @media only screen{
    #print-protokoll{
      margin: auto;
      padding: 20px;
    }
  }

  div#print-protokoll {
   background-color: white !important;
   font-family: Tahoma,Verdana,Segoe,sans-serif;
   font-size: 18px;
   div.logo {

     width: auto;
     img {
       max-height: 150px;
       width: auto;
       margin:auto;
     }
   }
   div.hopsteradresse {
     font-size: 0.9em;
   }
   hr {
     border-color: #DDD;
     margin-bottom:0;
   }
   h3 {
     font-size: 22px;
     font-weight: bold;
   }
   table {
     thead {
       tr {
         th {
           font-size: 14px;
           padding: 10px 10px;
         }
       }
     }
     tbody {
       tr {
         td {
           padding: 4px 8px !important;
         }
       }
     }
   }
   table.material {
     thead {
       th:nth-child(1), th:nth-child(2) {
         width: 15%;
       }
     }
     tbody {
       td:nth-child(1), td:nth-child(2) {
         width: 15%;
       }
     }
   }
   table.material {
     thead {
       th:nth-child(1) {
         width: 25%;
       }
       th:nth-child(2) {
         width: 15%;
       }
     }
     tbody {
       td:nth-child(1) {
         width: 25%;
       }
       td:nth-child(2) {
         width: 15%;
       }
     }
   }
 }
</style>
